import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { getAction } from '@/command/netTool'
import { relateTypeList, goodsTypeList } from '@/utils/textFile'

let linkAddressArr = []
let linkModuleArr = []
async function onFetchInit(data) {
  const [dataOne, dataTwo] = await Promise.all(
    ['/config/farmGoodsCategory/list', '/config/farmKingKongItem/list'].map((e, i) => getAction(e, {}, '/api'))
  )

  linkAddressArr = dataOne.data.map(e => {
    return { ...e, value: e.id }
  })
  linkModuleArr = dataTwo.data.map(e => {
    return { ...e, value: e.code }
  })

  return {
    form: data
  }
}

function getForm(form) {
  return [
    {
      title: '基础',
      form: [
        {
          name: '标题',
          type: 'input',
          labelAlign: 'left',
          key: 'name'
        },
        {
          name: '优先级',
          type: 'input',
          labelAlign: 'left',
          key: 'sort'
        }
      ]
    },
    {
      title: '链接',
      form: [
        {
          name: '链接方式',
          type: 'select',
          key: 'linkType',
          typeData: relateTypeList,
          onChange: e => {
            form.linkName = ''
            form.linkId = ''
          }
        },
        ...[
          // 外链
          {
            name: '链接地址',
            type: 'input',
            key: 'linkUrl',
            maxLength: 100,
            display: form.linkType == '1'
          },
          // 内部链接
          {
            name: '链接模块',
            type: 'select',
            display: form.linkType == '2',
            key: 'linkId',
            placeholder: '请选择链接模块',
            defaultValue: 'eat_drink',
            typeData: linkModuleArr
          },
          {
            name: '链接地址',
            type: 'select',
            key: 'categoryId',
            display: form.linkType == '2' && form.linkId == 'product_type',
            typeData: linkAddressArr
          },
          // 商品
          {
            name: '链接地址',
            type: 'select',
            key: 'goodsType',
            display: form.linkType == '3',
            defaultValue: '0',
            typeData: goodsTypeList()
          },
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '3',
            url: `/api/config/farmKingKong/listGoodsByType?type=${form.goodsType}`
          },
          // 店铺
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '4',
            url: `/api/commodity/farmMainBody/list?mainBodyName=${form.linkName}`
          },
          // 活动
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '5',
            url: '/api/farmActivity/list?appCode=sznc'
          },
          // 直播
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '6',
            url: '/api/market/farmLiveRoom/list'
          },
          // 专题页
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '7',
            url: '/api/farmShowSpecial/getList'
          },
          // 抽奖
          {
            name: '链接地址',
            type: 'storeSearch',
            key: 'linkName',
            valueKey: 'linkId',
            display: form.linkType == '8',
            url: '/api/farmActivityDrawPrize/list'
          }
        ].filter(e => e.display == true)
      ]
    },
    {
      form: [
        {
          name: '金刚区ICON',
          type: 'iconUpload',
          labelCol: 24,
          typeData: [
            {
              key: 'image2',
              desc: '@2x   xhdpi <br/>上传尺寸100*100px'
            },
            {
              key: 'image3',
              desc: '@3x   xxhdpi <br/>上传尺寸150*150px'
            }
          ]
        }
      ]
    }
  ]
}

function onOpen({ records, update }) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: { ...records },
      data: getForm
    },
    success({ data, setHidden }) {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function createForm({ create }) {
  apiTool.showDrawer({
    title: '新增',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: {
        name: '',
        sort: '',
        linkType: '',
        linkUrl: '',
        linkId: '',
        categoryId: '',
        goodsType: '',
        image2: '',
        image3: ''
      },
      data: getForm
    },
    success({ data, setHidden }) {
      create(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function delData({ del, form = {} } = {}) {
  del(form)
}

export default function() {
  const { update, create, del } = arguments[0]
  return {
    showFoot: false,
    url: '/config/farmKingKong/list',
    edit: '/config/farmKingKong/update',
    del: '/config/farmKingKong/delete?id=',
    create: '/config/farmKingKong/save',
    type: 'table',
    onFetchInit,
    props: {
      showFootButton: true,
      on: {
        handleAddData: () => createForm({ create })
      },
      columns: [
        {
          dataIndex: 'name',
          type: 'lt-200',
          customRender: function customRender(text, records, index, h) {
            return (
              <div>
                <img
                  src={records.image2}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '10px'
                  }}
                />
                <span>{text}</span>
              </div>
            )
          }
        },
        {
          dataIndex: 'sort',
          colSpan: { span: 8 }
        },
        {
          width: '110px',
          tableType: 'editDelete',
          onEdit: (text, records) => onOpen({ update, records }),
          onDel: (text, records) => delData({ del, form: records })
          //   customRender: function customRender(text, records, index, h) {
          //     return (
          //       <Button onClick={() => onOpen({ update, records })} ghost={true} type="primary">
          //         编辑
          //       </Button>
          //     )
          //   }
        }
      ]
    }
  }
}
